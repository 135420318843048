import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import React from 'react';
import { Button } from 'ui';
import SearchBreadcrumbs from '~/components/Search/SearchBreadcrumbs';
import { AccentS } from '~/components/TradeIn/AccentS';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import useTileVersions from '~/hooks/useTileVersions';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const LanderFeaturedCategoriesBlock: React.FC<Props> = ({ block }) => {
  const { category, lander } = useFacetFiltering();
  const tileVersions = useTileVersions(block);
  const mobile = useMobile();

  return (
    <section
      className="relative overflow-hidden bg-gradient-to-b from-green-500/25"
      id={`${block.layout}-${block.id}`}
    >
      <AccentS className="pointer-events-none absolute left-2/4 top-0 text-green-500 md:left-1/2" />

      <div className="container mb-0 px-4 py-6">
        <div className="mb-6">
          <SearchBreadcrumbs />
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:gap-4">
          <div className="flex-shrink-0 md:w-1/3 xl:w-1/4">
            <h1 className="mb-4">{block.title}</h1>
            <p className="prose">{block.description}</p>
          </div>

          <div className="w-full flex-1">
            <div className="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-6">
              {tileVersions?.map((tile, i) => (
                <a
                  key={tile.id}
                  href={tile.destination}
                  // Only show 4 on small desktop screens, 6 on larger screens and mobile
                  className={clsx({
                    'md:hidden lg:block': i > 3 && i < 6,
                    hidden: i > 5,
                  })}
                >
                  <div className="aspect-w-1 aspect-h-1 rounded-full">
                    <TileImageOrVideo
                      src={tile.background_image_url}
                      alt={tile.title}
                      className="rounded-full object-cover"
                      useFastly={
                        mobile
                          ? {
                              width: 200,
                              height: 200,
                              crop: '1:1',
                            }
                          : {
                              width: 400,
                              height: 400,
                              crop: '1:1',
                            }
                      }
                    />
                  </div>

                  <div className="mt-2 text-center font-semibold">
                    {tile.title}
                  </div>
                </a>
              ))}
            </div>

            {lander?.show_categories && category?.children?.length! > 0 && (
              <div className="mt-8 flex justify-center md:mt-4">
                <Button
                  variant="outlined"
                  onClick={() => {
                    document
                      .querySelector('#categories-section')
                      ?.scrollIntoView();
                  }}
                >
                  {block.cta || 'Show all categories'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LanderFeaturedCategoriesBlock;
