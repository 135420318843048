import React from 'react';
import ShopLink from '~/components/Links/ShopLink';
import { NavigationSection } from '~/hooks/useNavigation';
import ArrowRightIcon from '~/public/static/svg/ArrowRightIcon';

interface Props {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  sections: NavigationSection[];
}

const DesktopHeaderCmsNavigationSections: React.FC<Props> = ({
  onClick,
  sections,
}) => {
  const childCx =
    'cursor-pointer transition duration-200 text-slate-green-500 hover:text-turf-green-500 block px-4 py-1 hover:underline';

  const headerCx =
    'break-inside-avoid cursor-pointer transition duration-200 mb-1 text-base font-semibold block px-4 py-1 hover:underline';

  return (
    <div className="columns-3xs gap-x-4">
      {sections.map((section, i) => (
        <ul key={i} className="break-inside-avoid">
          <li role="presentation">
            <ShopLink
              path={section.destination || '#'}
              className={headerCx}
              internalRouter={false}
              onClick={onClick}
            >
              <span className="flex items-center space-x-1">
                <span>{section.title}</span>
                <ArrowRightIcon className="h-6 w-6" />
              </span>
            </ShopLink>
          </li>

          {section.children.map((child, j) => (
            <li key={j} className="first:mt-4 last:mb-4">
              <ShopLink
                path={child.destination || '#'}
                className={childCx}
                internalRouter={false}
                onClick={onClick}
              >
                {child.title}
              </ShopLink>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default DesktopHeaderCmsNavigationSections;
