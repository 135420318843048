import { QueryClient } from '@tanstack/react-query';
import { ApiResponse, fetcher, getBaseUrl, makeFetcher } from 'fetcher-session';
import { getButterPostsByTagForLanders } from '~/lib/buttercms/searchPages/posts';
import { RailsLander } from '~/typings/services/rails/landers';
import { RailsPredictiveSearchResult } from '~/typings/services/rails/predictiveSearch';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';

export const fetchLanderById = makeFetcher<
  RailsLander,
  [id: number, featuredListing?: boolean]
>((id: number, featuredListing?: boolean) => [
  () => `/v1/landers/${id}`,
  {
    params: {
      platform: 'web',
      featured_item: featuredListing ? '1' : undefined,
    },
  },
]);

export const fetchSuggestedLanderById = makeFetcher<RailsLander>(
  (id: number, featuredListing?: boolean) => [
    () => `/v1/landers/${id}/suggested_lander`,
    {
      params: {
        platform: 'web',
        featured_item: featuredListing ? '1' : undefined,
      },
      baseUrl: getBaseUrl('edge'),
    },
  ],
);

export const fetchLandersBySlug = makeFetcher<RailsLander[]>(
  (slug: string, featuredListing?: boolean) => [
    '/v1/landers',
    {
      params: {
        slug,
        platform: 'web',
        featured_item: featuredListing ? '1' : undefined,
      },
      baseUrl: getBaseUrl('edge'),
    },
  ],
);

export type PredictiveFilter =
  | 'canonical_model'
  | 'relatable_category'
  | 'listable'
  | 'sell_now';

export const fetchPredictiveSearch = (
  query: string,
  filters?: PredictiveFilter[],
) =>
  fetcher<RailsPredictiveSearchResult[]>('/v1/predictive_search', {
    params: {
      q: query,
      filter_type: filters,
    },
    baseUrl: getBaseUrl('edge'),
  });

type V2LanderResponse = ApiResponse<
  RailsLander & {
    page_view?: RailsPageView;
  },
  {
    block_id: number;
    layout: RailsBlock['layout'];
    objects: any[];
  }[]
>;

export async function fetchV2LanderData(
  landerId: number,
  queryClient: QueryClient,
) {
  const response = await fetcher<V2LanderResponse>(`/v2/landers/${landerId}`, {
    params: {
      platform: 'web',
    },
    dataOnly: false,
  });

  if (!response.data.page_view) {
    return response.data;
  }

  const promises: Promise<any>[] = [];
  for (let i = 0; i < response.data.page_view.blocks.length; i++) {
    const block = response.data.page_view.blocks[i];
    if (block.id == null) {
      block.id = i;
    }

    const meta = response.meta.find(m => m.block_id == block.id);
    if (!meta) {
      continue;
    }

    switch (block.layout) {
      case 'item-grid-1-row':
        if (queryClient) {
          queryClient.setQueryData(['item-block', block.id, 1], meta.objects);
        }
        break;

      case 'item-grid-2-rows':
        if (queryClient) {
          queryClient.setQueryData(['item-block', block.id, 2], meta.objects);
        }
        break;

      case 'item-grid-3-rows':
        if (queryClient) {
          queryClient.setQueryData(['item-block', block.id, 3], meta.objects);
        }
        break;

      case 'item-grid-horizontal-scroll':
        if (queryClient) {
          queryClient.setQueryData(
            ['horizontal-item-block', block.id],
            meta.objects,
          );
        }
        break;

      case 'top-models':
        queryClient.setQueryData(['shop-models', block.id], meta.objects);
        break;

      /*      case 'buying-guide':
        if (queryClient && block.tiles && categoryId != null) {
          for (let j = 0; j < block.tiles.length; j++) {
            const tile = block.tiles[j];
            if (tile.display_type === 'popular_models') {
                queryClient.setQueryData(
                  ['buying-guide-popular-models', tile.id],
                  () =>
                    fetchModelsByPosition(
                      // Why does TypeScript 4.2.4 not get this...
                      categoryId,
                      brandId,
                      10,
                    ),
                )
            }
          }
        }
        break;*/

      case 'blog-post-grid-3':
        if (!block.query?.tag_slug) {
          break;
        }
        promises.push(
          queryClient.prefetchQuery(['blog-posts', block.query?.tag_slug], () =>
            getButterPostsByTagForLanders(block.query?.tag_slug),
          ),
        );
        break;

      case 'user-feed':
        queryClient.setQueryData(['user-feed', block.id], meta.objects);
        break;

      default:
        promises.push(new Promise(resolve => resolve(null)));
        continue;
    }
  }

  await Promise.all(promises);

  return response.data;
}
