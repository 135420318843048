import { ChevronRightIcon, StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as FilledStarIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import React from 'react';
import { Message } from 'ui';
import Image from '~/components/Image';
import NewTabLink from '~/components/Links/NewTabLink';
import ShopLink from '~/components/Links/ShopLink';
import { ButterModelDescriptionPage } from '~/lib/buttercms/model-description/api';
import { RailsItemModel } from '~/typings/services/rails/item';

export default function ButterModelDescription({
  page,
  topCompetitors,
}: {
  page: ButterModelDescriptionPage;
  topCompetitors?: React.ReactNode;
}) {
  return (
    <div className="prose mx-auto max-w-[75ch]">
      <h2 className="mb-2">{page.name}</h2>
      <p>{page.simple_description}</p>

      {!page.perfect_for.hidden && (
        <div className="my-4">
          <ButterModelDescriptionPerfectFor page={page} />
        </div>
      )}

      {!page.key_features.hidden && (
        <>
          <h2 className="mb-2">{page.key_features.title}</h2>
          <table>
            <tbody>
              {page.key_features.key_features.map(keyFeature => (
                <tr key={keyFeature.feature}>
                  <td className="font-semibold">{keyFeature.feature}</td>
                  <td>{keyFeature.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {!page.top_competitors.hidden && (
        <>
          <h2 className="mb-2">{page.top_competitors.title}</h2>
          <div className="mb-4 grid grid-cols-2 gap-4">{topCompetitors}</div>
          <p>{page.top_competitors.description}</p>
        </>
      )}

      {!page.timeline.hidden && (
        <>
          {!!page.timeline.title && (
            <h2 className="mb-2">{page.timeline.title}</h2>
          )}
          {!!page.timeline.description && <p>{page.timeline.description}</p>}

          <div className="relative">
            <div className="bg-grey-200 absolute inset-y-2 left-[9px] w-px" />

            {page.timeline.timeline.map(event => (
              <div
                key={event.title}
                className="shadow-raised relative mb-6 ml-12 rounded p-4"
              >
                <div className="bg-grey-200 absolute -left-8 top-[29px] h-[1.5px] w-8" />
                <div className="absolute -left-11 top-6 h-3 w-3 rounded-full border-2 border-green-500 bg-white" />

                <div className="text-lg font-semibold">
                  {event.year ? `${event.year} - ` : null}
                  {event.title}
                </div>

                {event.content != null && (
                  <div dangerouslySetInnerHTML={{ __html: event.content }} />
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {!page.pros_and_cons.hidden && (
        <>
          {!!page.pros_and_cons.title && <h2>{page.pros_and_cons.title}</h2>}
          <div className="grid md:grid-cols-2 md:gap-4">
            <table>
              <tbody>
                <tr>
                  <th className="text-xl font-semibold">👍 Pros</th>
                </tr>
                {page.pros_and_cons.pros.map(pro => (
                  <tr key={pro.content}>
                    <td className="text-base">{pro.content}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table>
              <tbody>
                <tr className="text-xl font-semibold">
                  <th>👎 Cons</th>
                </tr>
                {page.pros_and_cons.cons.map(con => (
                  <tr key={con.content}>
                    <td className="text-base">{con.content}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {!page.review.hidden && (
        <>
          <ButterModelDescriptionReviewBlock page={page} />

          <div className="mt-8 grid gap-4 md:grid-cols-2">
            <div>
              <ButterModelDescriptionReviewStars page={page} />
            </div>

            {page.review.reviewer?.name ? (
              <div>
                <NewTabLink
                  href={page.review.reviewer.url}
                  className="flex items-center gap-4 text-black"
                >
                  <Image
                    src={page.review.reviewer.avatar}
                    alt=""
                    className="my-0 h-16 w-16 rounded-full object-cover"
                  />
                  <p className="flex-1 text-xl font-semibold">
                    {page.review.reviewer.name}
                  </p>

                  <ChevronRightIcon className="h-6" />
                </NewTabLink>

                <div>{page.review.reviewer.bio}</div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export function ButterModelDescriptionPerfectFor({
  page,
}: {
  page: ButterModelDescriptionPage;
}) {
  return (
    <Message success header={page.perfect_for.title}>
      {page.perfect_for.description}
    </Message>
  );
}

export function ButterModelDescriptionReviewBlock({
  className,
  compact,
  page,
}: {
  className?: string;
  compact?: boolean;
  page: ButterModelDescriptionPage;
}) {
  if (compact) {
    return (
      <div>
        <div
          className=""
          style={{
            marginTop: 24,
            fontSize: 64,
            lineHeight: 0.1,
          }}
        >
          &ldquo;
        </div>
        <blockquote className="leading-relaxed">
          {page.review.content}
        </blockquote>
        <div
          className="relative text-right"
          style={{
            marginTop: 24,
            fontSize: 64,
            lineHeight: 0.1,
          }}
        >
          &rdquo;
        </div>
      </div>
    );
  } else {
    return (
      <div className={clsx(className, 'flex gap-2')}>
        <div
          className=""
          style={{
            fontSize: 128,
            lineHeight: 1,
          }}
        >
          &ldquo;
        </div>
        <blockquote className="text-xl leading-relaxed">
          {page.review.content}
        </blockquote>
        <div
          className="relative top-16 self-end"
          style={{
            fontSize: 128,
            lineHeight: 1,
          }}
        >
          &rdquo;
        </div>
      </div>
    );
  }
}

export function ButterModelDescriptionReviewStars({
  page,
}: {
  page: ButterModelDescriptionPage;
}) {
  return (
    <>
      {page.review.reviews?.map(review => (
        <div className="flex items-center" key={review.name}>
          <div className="flex-1 font-semibold">{review.name}</div>

          <ReviewStars stars={Number(review.stars)} />
        </div>
      ))}
    </>
  );
}

function ReviewStars({ stars }: { stars: number }) {
  let ratings = [0, 0, 0, 0, 0];
  for (let i = 0; i < stars; i++) {
    ratings[i] = 1;
  }

  return ratings.map(rating => {
    return rating ? (
      <FilledStarIcon className="h-7 text-yellow-500" />
    ) : (
      <StarIcon className="text-grey-400 h-7" />
    );
  });
}

export function ButterModelDescriptionTopCompetitor({
  model,
}: {
  model: RailsItemModel;
}) {
  return (
    <ShopLink path={model.url} className="flex flex-col">
      <div className="aspect-w-1 aspect-h-1 mb-2 flex-1">
        <Image
          src={model.primary_image?.edge_url || model.primary_image?.large_url}
          alt={`${model.brand_name} ${model.name}`}
          useFastly={{
            height: 400,
          }}
          className="my-2 rounded"
        />
      </div>

      <div className="text-lg font-semibold">
        {model.brand_name} {model.name}
      </div>
    </ShopLink>
  );
}
