'use client';

import { clsx } from 'clsx';
import React, { useRef, useState } from 'react';
import DesktopHeaderCmsNavigationSections from '~/components/Chrome/Header/Desktop/DesktopHeaderCmsNavigationSections';
import DesktopHeaderCmsSeoNavigationSection from '~/components/Chrome/Header/Desktop/DesktopHeaderCmsSeoNavigationSection';
import ShopLink from '~/components/Links/ShopLink';
import ScaleFromTopFade from '~/components/Transitions/ScaleFromTopFade';
import { useDelayFn } from '~/hooks/useDelayFn';
import useLinkSiloNavigation from '~/hooks/useLinkSiloNavigation';
import { Navigation } from '~/hooks/useNavigation';

interface Props {
  navigation: Navigation[];
  route: string;
  category?: string;
}

const DesktopHeaderCmsNavigation: React.FC<Props> = ({
  navigation,
  route,
  category,
}) => {
  const linkSiloNavigation = useLinkSiloNavigation(navigation, route, category);

  const rowRef = useRef<HTMLUListElement>(null);
  const hoverEnabledRef = useRef(true);

  const [hovered, setHovered] = useState<null | {
    title: string | null;
    left?: number;
    right?: number;
  }>(null);
  const [hoverOpen, setHoverOpen] = useState(false);

  const [setHoverOpenTimeout, clearHoverOpenTimeout] = useDelayFn(
    (open: boolean) => setHoverOpen(open),
    150,
  );

  function onClickLink() {
    hoverEnabledRef.current = false;
    clearHoverOpenTimeout();
    setHoverOpenTimeout(false);
  }

  function onOpen() {
    if (hoverEnabledRef.current) {
      clearHoverOpenTimeout();
      setHoverOpen(true);
    }
  }

  const onHoverGroup = (
    group: Navigation,
  ): React.MouseEventHandler<HTMLAnchorElement> => {
    return e => {
      setHovered({
        title: group.title,
        left: 0,
      });

      setHoverOpenTimeout(true);
    };
  };

  const hoveredGroup =
    hovered &&
    navigation &&
    navigation.find(group => group.title === hovered.title);

  const onFinishedClosing = () => {
    hoverEnabledRef.current = true;
  };

  return (
    <>
      <div
        className="-ml-2 flex flex-1 items-center text-sm"
        onMouseOut={() => {
          clearHoverOpenTimeout();
          setHoverOpenTimeout(false);
        }}
      >
        <ul
          className="flex h-6 flex-wrap justify-between overflow-hidden leading-snug"
          ref={rowRef}
        >
          {linkSiloNavigation?.map((group, i) => (
            <li key={i} data-title={group.title}>
              <ShopLink
                className={clsx('block cursor-pointer px-2 py-1', {
                  'text-primary-500 font-semibold':
                    group.title === 'Auctions' ||
                    (hoverOpen && hovered?.title === group.title),
                })}
                path={group.destination || '#'}
                internalRouter={false}
                onMouseOver={onHoverGroup(group)}
                onClick={onClickLink}
              >
                {group.title}
              </ShopLink>

              {group.sections.length > 0 && (
                <DesktopHeaderCmsSeoNavigationSection
                  sections={group.sections}
                />
              )}
            </li>
          ))}
        </ul>
      </div>

      <ScaleFromTopFade
        show={
          !!(
            hoverOpen &&
            hovered?.title &&
            hoveredGroup?.sections &&
            hoveredGroup.sections.length > 0
          )
        }
        className="absolute -left-2 top-7 z-20 w-full overflow-auto rounded-b border-b bg-white p-2 text-sm shadow-lg"
        onMouseOver={onOpen}
        onMouseOut={() => setHoverOpenTimeout(false)}
        afterLeave={onFinishedClosing}
      >
        {hoveredGroup?.sections && (
          <DesktopHeaderCmsNavigationSections
            onClick={onClickLink}
            sections={hoveredGroup.sections}
          />
        )}
      </ScaleFromTopFade>
    </>
  );
};

export default DesktopHeaderCmsNavigation;
